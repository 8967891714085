<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page"> {{$router.type==1?'编辑':' 用户详情'}} </span>
    </div>
    <!-- 内容区 -->
    <div class="add flex-1">
      <a-layout class="height_100">
        <a-layout-sider class="a_slide_left ">
          <div class="basic">
            <div class="basic_nam"> <span class="names">王佳丽</span> <span class="uid">UID 234</span> </div>
            <div class="basic_phone">手机号：138 2651 2651</div>
            <div class="basic_content flex ">
              <div class="basic_contentLeft">
                <div class="basic_contentLeft_top flex align-item-center"> <span class="basic_contentLeft_toptxt">钱包</span> <span
                    class="details baseColor11 pointer"
                    @click="openPoint(1)"
                  >明细 <a-icon type="right" /></span> </div>
                <div class="basic_contentLeft_bot"> ￥35883.12</div>
              </div>
              <div class="basic_contentLine"></div>
              <div class="basic_contentRight flex-1">
                <div class="basic_contentLeft_top  flex align-item-center"> <span class="basic_contentLeft_toptxt">积分</span> <span
                    class="details baseColor11 pointer"
                    @click="openPoint(2)"
                  >明细 <a-icon type="right" /></span> </div>
                <div class="basic_contentLeft_bot"> 3588</div>
              </div>
            </div>
          </div>
          <div class="informationone">
            <h2 class="twoh2  flex j-sb pdr-26 mgb-20">
              <div class="twoh2_left">
                <span class="tpointer"></span>
                <span class="ttxt">基本信息</span>
              </div>
              <div class="twoh2_rgt">
                <span
                  class="ttxt"
                  @click="changebasic('basicone')"
                >{{ basicone? '展开':'收起' }} </span>
                <i
                  v-if="basicone"
                  class="meiye-icon  meiye-xiangxia colorjt"
                ></i>
                <i
                  v-else
                  class="meiye-icon  meiye-xiangxia colorjt revent"
                ></i>
              </div>

            </h2>
            <div
              class="informationone_content"
              v-if="!basicone"
            >
              <a-row class="mgl-10 mgb-24 mgl-40  ">
                <span class="leftBabletxt">商城昵称</span>
                <span class="rightBabletxt">{{'莉莉'}}</span>
              </a-row>
              <a-row class="mgl-10 mgb-24 mgl-40  mgb-20">
                <span class="leftBabletxt">用户首次来源</span>
                <span class="rightBabletxt">{{'商城小程序/直播间/合伙人小程序/…..'}}</span>
              </a-row>
              <a-row class="mgl-10 mgb-24 mgl-40  mgb-20">
                <span class="leftBabletxt">账号密码</span>
                <span class="rightBabletxt">{{'未设置'}}</span>
              </a-row>
              <a-row class="mgl-10 mgb-24 mgl-40  mgb-20">
                <span class="leftBabletxt">注册时间</span>
                <span class="rightBabletxt">{{'2023-06-06 19:16:40'}}</span>
              </a-row>
              <a-row class="mgl-10 mgb-24 mgl-40  mgb-20">
                <span class="leftBabletxt">上次活跃时间</span>
                <span class="rightBabletxt">{{'2023-06-06 19:16:40'}}</span>
              </a-row>
              <a-row class="mgl-10 mgb-24 mgl-40  mgb-20">
                <span class="leftBabletxt">微信号</span>
                <span class="rightBabletxt">{{'张晓岚hhh'}}</span>
              </a-row>
            </div>
          </div>
          <div class="informationone pdb-40">
            <h2 class="twoh2  flex j-sb pdr-26 mgb-20">
              <div class="twoh2_left">
                <span class="tpointer"></span>
                <span class="ttxt">认证信息</span>
              </div>
              <div class="twoh2_rgt">
                <span
                  class="ttxt"
                  @click="changebasic('basictwo')"
                >{{ basictwo? '展开':'收起' }} </span>
                <i
                  v-if="basictwo"
                  class="meiye-icon  meiye-xiangxia colorjt"
                ></i>
                <i
                  v-else
                  class="meiye-icon  meiye-xiangxia colorjt revent"
                ></i>
              </div>

            </h2>
            <div
              class="informationone_content"
              v-if="!basictwo"
            >
              <a-row class="mgl-10 mgb-24 mgl-40  ">
                <span class="leftBabletxt">用户姓名</span>
                <span class="rightBabletxt">{{'莉莉'}}</span>
              </a-row>
              <a-row class="mgl-10 mgb-24 mgl-40  mgb-20">
                <span class="leftBabletxt">出生日期</span>
                <span class="rightBabletxt">{{'1996-06-06'}}</span>
              </a-row>
              <a-row class="mgl-10 mgb-24 mgl-40  mgb-20">
                <span class="leftBabletxt">人脸识别</span>
                <span class="rightBabletxt">{{'已认证'}}</span>
              </a-row>
              <a-row class="mgl-10 mgb-24 mgl-40  mgb-20">
                <span class="leftBabletxt">证件类别</span>
                <span class="rightBabletxt">{{'中华人民共和国居民身份证'}}</span>
              </a-row>
              <a-row class="mgl-10 mgb-24 mgl-40  mgb-20">
                <span class="leftBabletxt">证件号码</span>
                <span class="rightBabletxt">{{'420984200009080456'}}</span>
              </a-row>
              <a-row class="mgl-10 mgb-24 mgl-40  mgb-20">
                <span class="leftBabletxt">用户性别</span>
                <span class="rightBabletxt">{{'女'}}</span>
              </a-row>
            </div>
          </div>
        </a-layout-sider>
        <a-layout>
          <a-layout-header class="a_slide_head pdl-24">
            <a-select
              mode="multiple"
              :default-value="['a1', 'b2']"
              style="width: 360px;"
              placeholder="请选择门店"
              showArrow
              allowClear
              :maxTagCount="3"
              @change="handleChange"
            >
              <a-select-option
                v-for="i in 25"
                :key="(i + 9).toString(36) + i"
              >
                {{ (i + 9).toString(36) + i }}
              </a-select-option>
            </a-select>
          </a-layout-header>
          <a-layout-content class="a_slide_body">
            <a-row class="pdl-24">
              <div class=" ">
                <a-radio-group
                  v-model="isdatatimes"
                  class="lable_radiogroup"
                  style="width:610px;"
                  @change="changeGglable"
                >
                  <a-radio-button
                    :value="1"
                    style="width:122px;text-align: center;"
                  >
                    储值卡{{'6'}}
                  </a-radio-button>
                  <a-radio-button
                    :value="2"
                    style="width:122px;text-align: center;"
                  >
                    卡项 8
                  </a-radio-button>
                  <a-radio-button
                    :value="3"
                    style="width:122px;text-align: center;"
                  >
                    服务 12
                  </a-radio-button>
                  <a-radio-button
                    :value="4"
                    style="width:122px;text-align: center;"
                  >
                    寄存产品 10
                  </a-radio-button>
                  <a-radio-button
                    :value="5"
                    style="width:122px;text-align: center;"
                  >
                    优惠券 2
                  </a-radio-button>
                </a-radio-group>
                <a-select
                  default-value="lucy"
                  style="width: 140px; margin-top:24px;float: right;margin-right: 24px;"
                  @change="handleChange"
                >
                  <a-select-option value="jack">
                    Jack
                  </a-select-option>
                  <a-select-option value="lucy">
                    Lucy
                  </a-select-option>
                  <a-select-option
                    value="disabled"
                    disabled
                  >
                    Disabled
                  </a-select-option>
                  <a-select-option value="Yiminghe">
                    yiminghe
                  </a-select-option>
                </a-select>
              </div>
            </a-row>
            <div class="cardsboard  pdl-24">
              <div class="swiper">
                <Swiper
                  :options="swiperOption"
                  ref="mySwiper"
                >
                  <swiper-slide>
                    <div class="sliderBox">
                      <a-row class="cards_row1"> <span class="font4 family_spe mgr-4">¥50000</span> <span class="font4 weight500 mgr-4">圣莉诗歌金卡</span> <span class="fat-right">待激活</span></a-row>
                      <div class="cards_line pdl-12"></div>
                      <a-row class="cards_row2 pdl-12">余额</a-row>
                      <a-row class="cards_row3 pdl-12">¥359888.00</a-row>
                      <a-row class="cards_row4 flex  pdl-12">
                        <div class="cards_row4_left">
                          <p class="cards_row4_ltop">到期时间</p>
                          <p class="cards_row4_lbot">2023-01-30 10:23</p>
                        </div>
                        <div class="cards_row4_right">
                          <p class="cards_row4_ltop">门店</p>
                          <p class="cards_row4_lbot">湖北省武汉市101店</p>
                        </div>
                      </a-row>
                      <div class="sliderBoxboard">
                        <a-button
                          size="small"
                          class="mgr-24 smallbtn wid-38"
                          @click="openDetails"
                        >查看
                        </a-button>
                        <a-button
                          size="small"
                          class="mgr-24 smallbtn wid-38"
                          @click="openDetails"
                        >删除
                        </a-button>

                      </div>
                    </div>

                  </swiper-slide>
                  <swiper-slide>
                    <div class="sliderBox">
                      <a-row class="cards_row1"> <span class="font4 family_spe mgr-4">¥50000</span> <span class="font4 weight500 mgr-4">圣莉诗歌金卡</span> <span class="fat-right">待激活</span></a-row>
                      <div class="cards_line pdl-12"></div>
                      <a-row class="cards_row2 pdl-12">余额</a-row>
                      <a-row class="cards_row3 pdl-12">¥359888.00</a-row>
                      <a-row class="cards_row4 flex  pdl-12">
                        <div class="cards_row4_left">
                          <p class="cards_row4_ltop">到期时间</p>
                          <p class="cards_row4_lbot">2023-01-30 10:23</p>
                        </div>
                        <div class="cards_row4_right">
                          <p class="cards_row4_ltop">门店</p>
                          <p class="cards_row4_lbot">湖北省武汉市101店</p>
                        </div>
                      </a-row>
                      <div class="sliderBoxboard">
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >查看
                        </a-button>
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >删除
                        </a-button>

                      </div>
                    </div>

                  </swiper-slide>
                  <swiper-slide>
                    <div class="sliderBox">
                      <a-row class="cards_row1"> <span class="font4 family_spe mgr-4">¥50000</span> <span class="font4 weight500 mgr-4">圣莉诗歌金卡</span> <span class="fat-right">待激活</span></a-row>
                      <div class="cards_line pdl-12"></div>
                      <a-row class="cards_row2 pdl-12">余额</a-row>
                      <a-row class="cards_row3 pdl-12">¥359888.00</a-row>
                      <a-row class="cards_row4 flex  pdl-12">
                        <div class="cards_row4_left">
                          <p class="cards_row4_ltop">到期时间</p>
                          <p class="cards_row4_lbot">2023-01-30 10:23</p>
                        </div>
                        <div class="cards_row4_right">
                          <p class="cards_row4_ltop">门店</p>
                          <p class="cards_row4_lbot">湖北省武汉市101店</p>
                        </div>
                      </a-row>
                      <div class="sliderBoxboard">
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >查看
                        </a-button>
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >删除
                        </a-button>

                      </div>
                    </div>

                  </swiper-slide>
                  <swiper-slide>
                    <div class="sliderBox">
                      <a-row class="cards_row1"> <span class="font4 family_spe mgr-4">¥50000</span> <span class="font4 weight500 mgr-4">圣莉诗歌金卡</span> <span class="fat-right">待激活</span></a-row>
                      <div class="cards_line pdl-12"></div>
                      <a-row class="cards_row2 pdl-12">余额</a-row>
                      <a-row class="cards_row3 pdl-12">¥359888.00</a-row>
                      <a-row class="cards_row4 flex  pdl-12">
                        <div class="cards_row4_left">
                          <p class="cards_row4_ltop">到期时间</p>
                          <p class="cards_row4_lbot">2023-01-30 10:23</p>
                        </div>
                        <div class="cards_row4_right">
                          <p class="cards_row4_ltop">门店</p>
                          <p class="cards_row4_lbot">湖北省武汉市101店</p>
                        </div>
                      </a-row>
                      <div class="sliderBoxboard">
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >查看
                        </a-button>
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >删除
                        </a-button>

                      </div>
                    </div>

                  </swiper-slide>
                  <swiper-slide>
                    <div class="sliderBox">
                      <a-row class="cards_row1"> <span class="font4 family_spe mgr-4">¥50000</span> <span class="font4 weight500 mgr-4">圣莉诗歌金卡</span> <span class="fat-right">待激活</span></a-row>
                      <div class="cards_line pdl-12"></div>
                      <a-row class="cards_row2 pdl-12">余额</a-row>
                      <a-row class="cards_row3 pdl-12">¥359888.00</a-row>
                      <a-row class="cards_row4 flex  pdl-12">
                        <div class="cards_row4_left">
                          <p class="cards_row4_ltop">到期时间</p>
                          <p class="cards_row4_lbot">2023-01-30 10:23</p>
                        </div>
                        <div class="cards_row4_right">
                          <p class="cards_row4_ltop">门店</p>
                          <p class="cards_row4_lbot">湖北省武汉市101店</p>
                        </div>
                      </a-row>
                      <div class="sliderBoxboard">
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >查看
                        </a-button>
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >删除
                        </a-button>

                      </div>
                    </div>

                  </swiper-slide>
                  <swiper-slide>
                    <div class="sliderBox">
                      <a-row class="cards_row1"> <span class="font4 family_spe mgr-4">¥50000</span> <span class="font4 weight500 mgr-4">圣莉诗歌金卡</span> <span class="fat-right">待激活</span></a-row>
                      <div class="cards_line pdl-12"></div>
                      <a-row class="cards_row2 pdl-12">余额</a-row>
                      <a-row class="cards_row3 pdl-12">¥359888.00</a-row>
                      <a-row class="cards_row4 flex  pdl-12">
                        <div class="cards_row4_left">
                          <p class="cards_row4_ltop">到期时间</p>
                          <p class="cards_row4_lbot">2023-01-30 10:23</p>
                        </div>
                        <div class="cards_row4_right">
                          <p class="cards_row4_ltop">门店</p>
                          <p class="cards_row4_lbot">湖北省武汉市101店</p>
                        </div>
                      </a-row>
                      <div class="sliderBoxboard">
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >查看
                        </a-button>
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >删除
                        </a-button>

                      </div>
                    </div>

                  </swiper-slide>
                  <swiper-slide class="mgr-24">
                    <div class="sliderBox">
                      <a-row class="cards_row1"> <span class="font4 family_spe mgr-4">¥50000</span> <span class="font4 weight500 mgr-4">圣莉诗歌金卡</span> <span class="fat-right">待激活</span></a-row>
                      <div class="cards_line pdl-12"></div>
                      <a-row class="cards_row2 pdl-12">余额</a-row>
                      <a-row class="cards_row3 pdl-12">¥359888.00</a-row>
                      <a-row class="cards_row4 flex  pdl-12">
                        <div class="cards_row4_left">
                          <p class="cards_row4_ltop">到期时间</p>
                          <p class="cards_row4_lbot">2023-01-30 10:23</p>
                        </div>
                        <div class="cards_row4_right">
                          <p class="cards_row4_ltop">门店</p>
                          <p class="cards_row4_lbot">湖北省武汉市101店</p>
                        </div>
                      </a-row>
                      <div class="sliderBoxboard">
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >查看
                        </a-button>
                        <a-button
                          size="small"
                          class="mgr-12 smallbtn"
                          @click="openDetails"
                        >删除
                        </a-button>

                      </div>
                    </div>

                  </swiper-slide>
                  <div
                    class="swiper-button-prev swiper-button-black"
                    slot="button-prev"
                  >
                    <i class="meiye-icon meiye-fanghui "></i>
                  </div>
                  <div
                    class="swiper-button-next swiper-button-black"
                    slot="button-next"
                  >
                    <i class="meiye-icon meiye-fanghui remote"></i>
                  </div>
                </Swiper>
              </div>

            </div>
          </a-layout-content>
          <a-layout-footer class="a_slide_foot">
            <a-row>
              <template>
                <div class="saleslip_tabs">
                  <a-tabs
                    default-active-key="1"
                    @change="callback"
                    class="default_tabs"
                    v-model="shoptype"
                  >
                    <a-tab-pane
                      key="1"
                      tab="销售单"
                    >
                    </a-tab-pane>
                    <a-tab-pane
                      key="2"
                      tab="核销记录"
                      force-render
                    >
                    </a-tab-pane>
                    <a-tab-pane
                      key="3"
                      tab="其他信息"
                    >
                    </a-tab-pane>
                  </a-tabs>
                </div>
              </template>
            </a-row>
            <a-row class="filter-wrap ">
              <!-- 复合搜索框 -->
              <ComposeInput
                :selectArr="selectArr"
                placeholderValue="搜索订单号"
                @getList="getListSearch"
              ></ComposeInput>
            </a-row>
            <div class="guigeTablebox  mgt-24 ">
              <a-table
                :pagination="false"
                row-key="goods_id"
                :columns="pay.columns"
                :data-source="pay.datasource"
                class="popTable3"
              >

                <template
                  slot="_5"
                  slot-scope="action, record"
                >
                  <a-button
                    size="small"
                    class="mgr-12 smallbtn"
                    @click="addRules(record,3)"
                  >查看</a-button>
                  <a-popover
                    trigger="hover"
                    placement="bottomRight"
                    :overlayStyle="{'width':'286px'}"
                  >
                    <template slot="content">
                      <div class="font3 mgb-12">订单商品信息</div>
                      <div class="shopdetails flex">
                        <div class="shopdetails_img"></div>
                        <div class="shopdetails_txt">
                          <p class="shopdetails_names">美娜多紧致按摩护理</p>
                          <p class="shopdetails_gg"> <span class="mgr-16">150ml</span> <span class=""> ¥1233.21 X5</span></p>
                        </div>
                      </div>
                      <div class="shopdetails flex">
                        <div class="shopdetails_img"></div>
                        <div class="shopdetails_txt">
                          <p class="shopdetails_names">美娜多紧致按摩护理</p>
                          <p class="shopdetails_gg"> <span class="mgr-16">150ml</span> <span class=""> ¥1233.21 X5</span></p>
                        </div>
                      </div>
                    </template>
                    <a-button
                      size="small"
                      class="mgr-12 smallbtn"
                      @click="addRules(record,4)"
                    >商品明细</a-button>
                  </a-popover>

                </template>
              </a-table>
            </div>
          </a-layout-footer>
        </a-layout>
      </a-layout>
    </div>
    <!-- 右側  查看储值卡 -->
    <a-drawer
      :title="drawerObj.title"
      placement="right"
      :visible="drawerObj.drawerIsShow"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :destroyOnClose="true"
      :closable="true"
      wrapClassName="detailsDraw"
      width="950"
    >
      <!-- 基础信息 储值卡 -->
      <div class="baseIndo flex justify-between">
        <div class="baseLeft">
          <p class="pnames font4">{{ '99会籍卡' }}</p>
          <p class="pshops"><span class="font5">购买门店：</span> <span class="font4">{{ '湖北省武汉101门店' }}</span> </p>
        </div>
        <div class="baseRight mgr-32">
          <a-button class="stepbackbtn mgr-20 bigheightbn wid-80 ant-btn">
            开卡
          </a-button>
          <a-button
            class="stepbtnnext1 bigheightbn wid-80 ant-btn ant-btn-primary"
            @click="cardsEditRocords.visible=true"
          >
            修改记录
          </a-button>
        </div>
      </div>
      <!-- 消耗信息 -->
      <div class="consumeInfo">
        <a-row class="mgb-20">
          <span class="labletxt">购买时间</span>
          <span class="valuetxt">{{'2023/12/12 10:32:12'}}</span>
          <span class="labletxt">现金率</span>
          <span class="valuetxt">{{'96.2%'}}</span>
        </a-row>
        <a-row class="mgb-24">
          <span class="labletxt">到期时间</span>
          <span class="valuetxt valuetxt_position">{{'2023/12/12 10:32:12'}}
            <span class="mgl-12 mgr-12">剩余{{'360'}}天</span>
            <span
              class="toeditoricon"
              @click="goEditor"
            ><i class="meiye-icon meiye-bianji"></i></span>
          </span>
          <span class="labletxt">销售单号</span>
          <span class="valuetxt">{{'29999888888888323'}}</span>
        </a-row>
        <!-- 卡片 -->
        <div class="boardcards flex ">
          <div class=" flex flex-column align-item-center">
            <span class="mgb-8  font3">余额</span>
            <span class=" baseColor11 font18 fontb">{{ '¥2,212.12' }}</span>
          </div>
          <div class="flex flex-column align-item-center">
            <span class="mgb-8 font3">面值</span>
            <span class="font18 fontb">{{ '¥32321' }}</span>
          </div>
          <div class="flex flex-column align-item-center">
            <span class="mgb-8  font3">商品金额</span>
            <span class=" font18 fontb">{{ '¥6776.12' }}</span>
          </div>
          <div class="flex flex-column align-item-center">
            <span class=" mgb-8 font3">实际支付</span>
            <span class=" font18 fontb">{{ '¥5833.12' }}</span>
          </div>
        </div>
      </div>
      <!-- 购买列表 -->
      <div class="buyLisy mgl-8  mgt-40">
        <!-- tabs -->
        <a-row>
          <template>
            <div class="saleslip_tabs">
              <a-tabs
                default-active-key="1"
                @change="callbackRange"
                class="default_tabs"
                v-model="rangeObj.rangeType"
              >
                <a-tab-pane
                  key="1"
                  tab="适用范围"
                >
                  <!-- 适用范围 -->
                  <div class="consTable">
                    <div class="rangebox">
                      <a-row class="twoh2 mgt-9 mgb-12">
                        <span class="tpointer"></span>
                        <span class="ttxt">适用产品</span>
                      </a-row>
                      <!-- 部分产品 部分分类  部分品牌-->
                      <a-table
                        class="popTable3"
                        :pagination="false"
                        :rowKey="record=>record.id"
                        :loading="rangeObj.loading"
                        :columns="rangeObj.rangeColums"
                        :scroll="{  y: 480  }"
                        :data-source="rangeObj.rangeDate"
                      >
                        <template
                          slot="_0"
                          slot-scope="action, record"
                        >
                          <span>{{ record.product_name||'--' }}</span>
                        </template>
                        <template
                          slot="_1"
                          slot-scope="action, record"
                        >
                          <span>{{ record.product_price||'--' }}</span>
                        </template>
                        <!-- 品牌 -->
                        <div
                          slot="brands"
                          class="bigdropdown pointer"
                          style="padding-left:0px;"
                          :class="brandsIsShow?'hovedropdown':''"
                        >
                          <div class="cursor-pointer hoverClass">
                            <a-popover
                              :title="null"
                              v-model="brandsIsShow"
                              trigger="click"
                              placement="bottomLeft"
                            >
                              <template slot="content">
                                <a-select
                                  class="width-min-200"
                                  optionFilterProp="label"
                                  showSearch
                                  allowClear
                                  v-model="rangeObj.goods_brand"
                                  @change="event=>getbrandsItem('brands',event)"
                                  placeholder="全部品牌"
                                >
                                  <a-select-option
                                    v-for="it in brandsList"
                                    :key="it.brand_id"
                                    :value="it.brand_id"
                                    :label="it.brand_name"
                                  >
                                    {{ it.brand_name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <span class="flex align-item-center">
                                <i
                                  style="font-size:15px;color:#9EA6A4; padding-left:8px; margin-right: 3px;"
                                  class="meiye-icon  meiye-fangan"
                                ></i>
                                品牌
                                <i
                                  style="font-size:9px;margin-right: 10px;"
                                  class="meiye-icon meiye-xiala"
                                ></i>
                              </span>
                            </a-popover>
                          </div>

                        </div>
                        <template
                          slot="_2"
                          slot-scope="action, record"
                        >
                          <span>{{ record.types||'--' }}</span>
                        </template>
                        <template
                          slot="_3"
                          slot-scope="action, record"
                        >
                          <span>{{ record.brands||'--' }}</span>
                        </template>
                      </a-table>

                      <a-row class="twoh2 mgt-9 mgb-12">
                        <span class="tpointer"></span>
                        <span class="ttxt ">适用服务</span>
                      </a-row>
                      <!-- 部分服务 部分分类 部分品牌  -->
                      <a-row class="mgl-12 font4">全部服务</a-row>
                      <a-row class="twoh2 mgt-9 mgb-12 mgt-40">
                        <span class="tpointer"></span>
                        <span class="ttxt">适用卡项</span>
                      </a-row>
                      <!-- 部分卡项 部分分类 部分品牌  -->
                      <a-row class="mgl-12 font4">全部卡项</a-row>

                    </div>

                  </div>
                </a-tab-pane>
                <a-tab-pane
                  key="2"
                  tab="储值卡变动信息"
                  force-render
                >
                  <div class="consTable">
                    <div class="rangebox">
                      <a-row class="twoh2 mgt-9 mgb-12">
                        <span class="tpointer"></span>
                        <span class="ttxt">储值卡变动信息</span>
                      </a-row>
                      <!-- 部分产品 部分分类 部分品牌  -->
                      <a-table
                        class="popTable3"
                        :pagination="false"
                        :rowKey="record=>record.id"
                        :loading="rangeObj.loading"
                        :columns="rangeObj.cardsColums"
                        :scroll="{  y: 480  }"
                        :data-source="rangeObj.cardsDate"
                      >
                        <template
                          slot="_0"
                          slot-scope="action, record"
                        >
                          <span>{{ record.product_name||'--' }}</span>
                        </template>
                        <template
                          slot="_1"
                          slot-scope="action, record"
                        >
                          <span>{{ record.product_price||'--' }}</span>
                        </template>
                        <!-- 品牌 -->
                        <div
                          slot="brands"
                          class="bigdropdown pointer"
                          style="padding-left:0px;"
                          :class="brandsIsShow?'hovedropdown':''"
                        >
                          <div class="cursor-pointer hoverClass">
                            <a-popover
                              :title="null"
                              v-model="brandsIsShow"
                              trigger="click"
                              placement="bottomLeft"
                            >
                              <template slot="content">
                                <a-select
                                  class="width-min-200"
                                  optionFilterProp="label"
                                  showSearch
                                  allowClear
                                  v-model="rangeObj.goods_brand"
                                  @change="event=>getbrandsItem('brands',event)"
                                  placeholder="全部品牌"
                                >
                                  <a-select-option
                                    v-for="it in brandsList"
                                    :key="it.brand_id"
                                    :value="it.brand_id"
                                    :label="it.brand_name"
                                  >
                                    {{ it.brand_name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <span class="flex align-item-center">
                                <i
                                  style="font-size:15px;color:#9EA6A4; padding-left:8px; margin-right: 3px;"
                                  class="meiye-icon  meiye-fangan"
                                ></i>
                                品牌
                                <i
                                  style="font-size:9px;margin-right: 10px;"
                                  class="meiye-icon meiye-xiala"
                                ></i>
                              </span>
                            </a-popover>
                          </div>

                        </div>
                        <template
                          slot="_2"
                          slot-scope="action, record"
                        >
                          <span>{{ record.types||'--' }}</span>
                        </template>
                        <template
                          slot="_3"
                          slot-scope="action, record"
                        >
                          <span>{{ record.brands||'--' }}</span>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane
                  key="3"
                  tab="备注"
                >
                  <div class="consTable">
                    <div class="rangebox">
                      <a-row class="twoh2 mgt-9 mgb-12 ">
                        <span class="tpointer"></span>
                        <span class="ttxt">备注 </span>
                        <a-button
                          type="primary"
                          class="table-btn-clk"
                          style="  z-index: 1;margin-left:12px;margin-top: -4px;height: 32px;"
                          @click="memo.memovisible=true;"
                        >
                          <i
                            class="meiye-icon meiye-routeicon-add-copy"
                            style="color:#fff;font-size:12px;margin-right:4px;"
                          ></i>
                          添加备注
                        </a-button>
                      </a-row>
                      <!-- 部分产品 部分分类 部分品牌  -->
                      <a-table
                        class="popTable3"
                        :pagination="false"
                        :rowKey="record=>record.id"
                        :loading="rangeObj.loading"
                        :columns="rangeObj.notesColums"
                        :scroll="{  y: 480  }"
                        :data-source="rangeObj.notesDate"
                      >
                        <template
                          slot="_0"
                          slot-scope="action, record"
                        >
                          <span>{{ record.product_name||'--' }}</span>
                        </template>
                        <template
                          slot="_1"
                          slot-scope="action, record"
                        >
                          <span>{{ record.product_price||'--' }}</span>
                        </template>
                        <!-- 品牌 -->
                        <div
                          slot="brands"
                          class="bigdropdown pointer"
                          style="padding-left:0px;"
                          :class="brandsIsShow?'hovedropdown':''"
                        >
                          <div class="cursor-pointer hoverClass">
                            <a-popover
                              :title="null"
                              v-model="brandsIsShow"
                              trigger="click"
                              placement="bottomLeft"
                            >
                              <template slot="content">
                                <a-select
                                  class="width-min-200"
                                  optionFilterProp="label"
                                  showSearch
                                  allowClear
                                  v-model="rangeObj.goods_brand"
                                  @change="event=>getbrandsItem('brands',event)"
                                  placeholder="全部品牌"
                                >
                                  <a-select-option
                                    v-for="it in brandsList"
                                    :key="it.brand_id"
                                    :value="it.brand_id"
                                    :label="it.brand_name"
                                  >
                                    {{ it.brand_name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <span class="flex align-item-center">
                                <i
                                  style="font-size:15px;color:#9EA6A4; padding-left:8px; margin-right: 3px;"
                                  class="meiye-icon  meiye-fangan"
                                ></i>
                                品牌
                                <i
                                  style="font-size:9px;margin-right: 10px;"
                                  class="meiye-icon meiye-xiala"
                                ></i>
                              </span>
                            </a-popover>
                          </div>

                        </div>
                        <template
                          slot="_2"
                          slot-scope="action, record"
                        >
                          <span>{{ record.types||'--' }}</span>
                        </template>
                        <template
                          slot="_3"
                          slot-scope="action, record"
                        >
                          <span>{{ record.brands||'--' }}</span>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
          </template>
        </a-row>
      </div>
    </a-drawer>
    <!--查看卡项 drawer-->
    <a-drawer
      :title="planObj.title"
      placement="right"
      :visible="planObj.drawerIsShow"
      :after-visible-change="afterVisibleChange"
      @close="oncardClose"
      :destroyOnClose="true"
      :closable="true"
      wrapClassName="detailsDraw"
      width="950"
    >
      <!--卡项 基础信息 储值卡 -->
      <div class="baseIndo flex justify-between">
        <div class="baseLeft">
          <p class="pnames font4">{{ '方案一' }}</p>
          <p class="pshops"><span class="font5">购买门店：</span> <span class="font4">{{ '湖北省武汉101门店' }}</span> </p>
        </div>
        <div class="baseRight mgr-32">
          <a-button class="stepbackbtn mgr-20 bigheightbn wid-80 ant-btn">
            停卡
          </a-button>
          <a-button
            class="stepbtnnext1 bigheightbn wid-80 ant-btn ant-btn-primary"
            @click="cardsEditRocords.visible=true"
          >
            修改记录
          </a-button>
        </div>
      </div>
      <!--卡项 消耗信息 -->
      <div class="consumeInfo">
        <a-row class="mgb-20">
          <span class="labletxt">剩余可购买次数</span>
          <span class="valuetxt">{{'23'}}</span>
          <span class="labletxt">购买时间</span>
          <span class="valuetxt">{{'2023/12/12 10:32:12'}}</span>

        </a-row>
        <a-row class="mgb-24">
          <span class="labletxt">激活时间</span>
          <span class="valuetxt">{{'2023/12/12 10:32:12'}}</span>
          <span class="labletxt">到期时间</span>
          <span class="valuetxt valuetxt_position">{{'2023/12/12 10:32:12'}}
            <span class="mgl-12 mgr-12">剩余{{'360'}}天</span>
            <span
              class="toeditoricon"
              @click="goEditor"
            ><i class="meiye-icon meiye-bianji"></i></span>
          </span>
        </a-row>
        <a-row class="mgb-24">
          <span class="labletxt">商品金额</span>
          <span class="valuetxt">{{'¥2233.12'}}</span>
          <span class="labletxt">实际支付</span>
          <span class="valuetxt valuetxt_position">{{'¥2233.12'}}</span>
        </a-row>
        <a-row class="mgb-24">
          <span class="labletxt">销售单号</span>
          <span class="valuetxt">{{'29999888888888323'}}</span>
        </a-row>

      </div>
      <!-- 方案表格 -->
      <div class="buyLisy mgl-8  mgt-40">
        <!-- tabs -->
        <a-row>
          <template>
            <div class="saleslip_tabs">
              <a-tabs
                default-active-key="1"
                @change="callbackRange"
                class="default_tabs"
                v-model="planObj.rangeType"
              >
                <a-tab-pane
                  key="1"
                  tab="卡项信息"
                >
                  <!--卡项信息卡片 -->
                  <div class="plancardsboard flex">
                    <div class=" flex flex-column align-item-center">
                      <span class="mgb-8  font3">总兑付次数</span>
                      <span class="font18 fontb mgb-8">{{ '32' }}</span>
                      <span class="mgb-8  font3">已消耗权益/项</span>
                      <span class="font18 fontb">{{ '32' }}</span>
                    </div>
                    <div class=" flex flex-column align-item-center">
                      <span class="mgb-8  font3 ">总项数</span>
                      <span class="font18 fontb  mgb-8">{{ '20' }}</span>
                      <span class="mgb-8  font3">剩余兑付次数</span>
                      <span class="font18 fontb">{{ '32' }}</span>
                    </div>
                    <div class=" flex flex-column align-item-center">
                      <span class="mgb-8  font3">单次最多消耗/项</span>
                      <span class="font18 fontb  mgb-8">{{ '32' }}</span>
                      <span class="mgb-8  font3">剩余消耗权益/项</span>
                      <span class="font18 fontb">{{ '32' }}</span>
                    </div>
                    <div class=" flex flex-column align-item-center">
                      <span class="mgb-8  font3">已兑付次数</span>
                      <span class="font18 fontb  mgb-8">{{ '32' }}</span>
                      <span class="mgb-8  font3">折算剩余资产</span>
                      <span class="baseColor11 font18 fontb">{{ '￥2023.8' }}</span>
                    </div>
                  </div>
                  <div class="consTable">
                    <div class="rangebox">
                      <!-- 卡项 列表 分组 单个-->
                      <a-table
                        class="popTable3 guigeTableSpec"
                        :pagination="false"
                        :rowKey="record=>record.id"
                        :loading="planObj.loading"
                        :columns="planObj.rangeColums"
                        :scroll="{  y: 480,x: 1200  }"
                        :data-source="planObj.rangeDate"
                        :rowClassName="getRowClassname"
                      >
                        <template
                          slot="_0"
                          slot-scope="action, record"
                        >
                          <span class="guigeTableSpec_th"> {{ record.isGroup }}</span>
                        </template>
                        <template
                          slot="_1"
                          slot-scope="action, record"
                        >
                          <a-tooltip placement="topLeft">
                            <template slot="title">
                              {{  record.goods_name}}
                            </template>
                            {{  record.goods_name}}
                          </a-tooltip>
                        </template>
                        <template
                          slot="_2"
                          slot-scope="action, record"
                        >
                          <span>{{ record.types||'--' }}</span>
                        </template>
                        <template
                          slot="_3"
                          slot-scope="action, record"
                        >
                          <span>{{ record.brands||'--' }}</span>
                        </template>
                        <template
                          slot="_4"
                          slot-scope="action, record"
                        >
                          <span>{{ record.classes||'--' }}</span>
                        </template>
                        <template
                          slot="_5"
                          slot-scope="action, record"
                        >
                          <span>{{ record.consume||'--' }}</span>
                        </template>
                        <template
                          slot="_6"
                          slot-scope="action, record"
                        >
                          <span>{{ record.group_limit_count||'--' }}</span>
                        </template>
                        <template
                          slot="_7"
                          slot-scope="action, record"
                        >
                          <a-button
                            size="small"
                            class="mgr-12 smallbtn"
                            @click="planObj.modalVisible=true"
                          >查看消耗</a-button>
                          <!--todo 多开一个drawer 消耗单详情 -->
                        </template>
                      </a-table>
                    </div>
                    <a-row class="mgt-24">
                      <span class="labletxt">总项数：</span>
                      <span class="valuetxt baseColor11">{{'30项'}}</span>
                    </a-row>
                  </div>
                </a-tab-pane>
                <a-tab-pane
                  key="2"
                  tab="核销信息"
                  force-render
                >
                  <div class="consTable">
                    <div class="rangebox">
                      <a-table
                        class="popTable3"
                        :pagination="false"
                        :rowKey="record=>record.id"
                        :loading="planObj.loading"
                        :columns="planObj.cardsColums"
                        :scroll="{  y: 480  }"
                        :data-source="planObj.cardsDate"
                      >
                        <template
                          slot="_3"
                          slot-scope="action, record"
                        >
                          <a-tooltip>
                            <template slot="title">
                              {{ record.brands||'--' }}
                            </template>
                            <span>{{ record.brands||'--' }}</span>
                          </a-tooltip>
                        </template>
                        <template
                          slot="_6"
                          slot-scope="action, record"
                        >
                          <a-button
                            size="small"
                            class="mgr-12 smallbtn"
                            @click="planObj.modalVisible=true"
                          >详情</a-button>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane
                  key="3"
                  tab="备注"
                >
                  <div class="consTable">
                    <div class="rangebox">
                      <a-row class="twoh2 mgt-9 mgb-12 ">
                        <span class="tpointer"></span>
                        <span class="ttxt">备注 </span>
                        <a-button
                          type="primary"
                          class="table-btn-clk"
                          style="  z-index: 1;margin-left:12px;margin-top: -4px;height: 32px;"
                          @click="memo.memovisible=true;"
                        >
                          <i
                            class="meiye-icon meiye-routeicon-add-copy"
                            style="color:#fff;font-size:12px;margin-right:4px;"
                          ></i>
                          添加备注
                        </a-button>
                      </a-row>
                      <!-- 部分产品 部分分类 部分品牌  -->
                      <a-table
                        class="popTable3"
                        :pagination="false"
                        :rowKey="record=>record.id"
                        :loading="rangeObj.loading"
                        :columns="rangeObj.notesColums"
                        :scroll="{  y: 480  }"
                        :data-source="rangeObj.notesDate"
                      >
                        <template
                          slot="_0"
                          slot-scope="action, record"
                        >
                          <span>{{ record.product_name||'--' }}</span>
                        </template>
                        <template
                          slot="_1"
                          slot-scope="action, record"
                        >
                          <span>{{ record.product_price||'--' }}</span>
                        </template>
                        <!-- 品牌 -->
                        <div
                          slot="brands"
                          class="bigdropdown pointer"
                          style="padding-left:0px;"
                          :class="brandsIsShow?'hovedropdown':''"
                        >
                          <div class="cursor-pointer hoverClass">
                            <a-popover
                              :title="null"
                              v-model="brandsIsShow"
                              trigger="click"
                              placement="bottomLeft"
                            >
                              <template slot="content">
                                <a-select
                                  class="width-min-200"
                                  optionFilterProp="label"
                                  showSearch
                                  allowClear
                                  v-model="rangeObj.goods_brand"
                                  @change="event=>getbrandsItem('brands',event)"
                                  placeholder="全部品牌"
                                >
                                  <a-select-option
                                    v-for="it in brandsList"
                                    :key="it.brand_id"
                                    :value="it.brand_id"
                                    :label="it.brand_name"
                                  >
                                    {{ it.brand_name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <span class="flex align-item-center">
                                <i
                                  style="font-size:15px;color:#9EA6A4; padding-left:8px; margin-right: 3px;"
                                  class="meiye-icon  meiye-fangan"
                                ></i>
                                品牌
                                <i
                                  style="font-size:9px;margin-right: 10px;"
                                  class="meiye-icon meiye-xiala"
                                ></i>
                              </span>
                            </a-popover>
                          </div>

                        </div>
                        <template
                          slot="_2"
                          slot-scope="action, record"
                        >
                          <span>{{ record.types||'--' }}</span>
                        </template>
                        <template
                          slot="_3"
                          slot-scope="action, record"
                        >
                          <span>{{ record.brands||'--' }}</span>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
          </template>
        </a-row>
      </div>
      <!-- 消耗明细 -->
      <a-modal
        :title="planObj.modalTitle"
        :visible="planObj.modalVisible"
        width="980px"
        wrapClassName="commonModel2"
        @cancel="planObj.modalVisible=false"
        :destroyOnClose="true"
        :footer="null"
      >
        <div class="">
          <!-- 卡片 -->
          <div class="consumptionDetails mgb-24">
            <div class="boardcards flex ">
              <div class=" flex flex-column align-item-center">
                <span class="mgb-8  font3">最多消耗项</span>
                <span class=" baseColor11 font18 fontb">{{ '34' }}</span>
              </div>
              <div class="flex flex-column align-item-center">
                <span class="mgb-8 font3">已消耗项</span>
                <span class="font18 fontb">{{ '67' }}</span>
              </div>
              <div class="flex flex-column align-item-center">
                <span class="mgb-8  font3">剩余消耗项 </span>
                <span class=" font18 fontb">{{ '34' }}</span>
              </div>
            </div>
          </div>
          <div class="consTable">
            <a-table
              class="popTable3"
              :pagination="false"
              :rowKey="record=>record.id"
              :loading="planObj.conLoading"
              :columns="planObj.conColums"
              :scroll="{  y:380  }"
              :data-source="planObj.mockDataObj"
            >
              <template
                slot="_0"
                slot-scope="action, record"
              >
                <span>{{ record.product_time||'--' }}</span>

              </template>

              <template
                slot="_2"
                slot-scope="action, record"
              >
                <span>{{ record.product_count||'--' }}</span>
              </template>

              <template
                slot="_3"
                slot-scope="action, record"
              >
                <span>{{ record.product_beaty||'--' }}</span>
              </template>

              <template
                slot="_4"
                slot-scope="action, record"
              >
                <a-tooltip>
                  <template slot="title">
                    {{ record.product_store||'--' }}
                  </template>
                  <span>{{ record.product_store||'--' }}</span>
                </a-tooltip>
              </template>
            </a-table>

          </div>
        </div>
      </a-modal>
    </a-drawer>
    <!-- 服务 drawer-->
    <!-- 寄存产品 drawer-->
    <!-- 核销商品-商品记录 drawer-->

    <!-- 钱包明细 积分明细-->
    <a-modal
      :visible="walletDetails.visible"
      width="980px"
      wrapClassName="commonModel2"
      @cancel="walletDetails.visible=false"
      :destroyOnClose="true"
      :footer="null"
    >
      <div class="">
        <div class="consTable">
          <a-table
            class="popTable3"
            :pagination="false"
            :rowKey="record=>record.id"
            :loading="walletDetails.loading"
            :columns="walletDetails.columns"
            :scroll="{  y: 480  }"
            :data-source="walletDetails.tableData"
          >
            <template
              slot="_0"
              slot-scope="action, record"
            >
              <span>{{ record.revenue_expenditure_type||'--' }}</span>

            </template>
            <template
              slot="_1"
              slot-scope="action, record"
            >
              <a-tooltip>
                <template slot="title">
                  {{ record.store||'--' }}
                </template>
                <span>{{ record.store||'--' }}</span>
              </a-tooltip>
            </template>
            <template
              slot="_2"
              slot-scope="action, record"
            >
              <span>{{ record.sum||'--' }}</span>
            </template>
            <template slot="times">
              <div class="cursor-pointer bigdropdown  bigdropdown3 wid-102">
                <a-popover
                  :title="null"
                  v-model="visibleTime"
                  trigger="click"
                  placement="bottomRight"
                >
                  <template slot="content">
                    <!-- 日期选择器 -->
                    <a-range-picker
                      @change="setMonth"
                      class="data_picker"
                    >
                      <i
                        slot="suffixIcon"
                        class="meiye-icon meiye-rili-moren"
                        :class="{ 't-c-g': isactive }"
                        style="font-size:12px;color:#9ea6a4 ;background: @baseColor41;margin-top: -5px;"
                      ></i>
                    </a-range-picker>

                  </template>
                  <span style="width:94px;display: inline-block">
                    <i
                      class="meiye-icon meiye-riqi "
                      style="color:#9ea6a4;"
                    ></i>
                    时间
                    <i
                      class="meiye-icon meiye-xiala"
                      style="width:10px; margin-top: -1px;font-size:12px;"
                    ></i>
                  </span>
                </a-popover>
              </div>
            </template>
            <template
              slot="_3"
              slot-scope="action, record"
            >
              <span>{{ record.times||'--' }}</span>
            </template>
            <template
              slot="_4"
              slot-scope="action, record"
            >
              <span>{{ record.serial_number||'--' }}</span>
            </template>
            <template
              slot="_5"
              slot-scope="action, record"
            >
              <a-tooltip>
                <template slot="title">
                  {{ record.notes||'--' }}
                </template>
                <span>{{ record.notes ||'--' }}</span>
              </a-tooltip>
            </template>
          </a-table>

        </div>
      </div>
      <template slot="title">
        <div style="margin: 3px 0;">
          <span
            class="titmodel"
            style="margin-right:8px;"
          >{{  walletDetails.type==1?'钱包消费明细':'积分明细' }}</span>
        </div>
      </template>
    </a-modal>

    <!-- 修改记录 -->
    <a-modal
      title="修改记录"
      :visible="cardsEditRocords.visible"
      width="980px"
      wrapClassName="commonModel2"
      @cancel="cardsEditRocords.visible=false"
      :destroyOnClose="true"
      :footer="null"
    >
      <div class="">
        <div class="consTable">
          <a-table
            class="popTable3"
            :pagination="false"
            :rowKey="record=>record.id"
            :loading="cardsEditRocords.loading"
            :columns="cardsEditRocords.columns"
            :scroll="{  y: 480  }"
            :data-source="cardsEditRocords.tableData"
          >
            <template
              slot="_0"
              slot-scope="action, record"
            >
              <span>{{ record.revenue_expenditure_type||'--' }}</span>

            </template>
            <template
              slot="_1"
              slot-scope="action, record"
            >
              <a-tooltip>
                <template slot="title">
                  {{ record.store||'--' }}
                </template>
                <span>{{ record.store||'--' }}</span>
              </a-tooltip>
            </template>
            <template
              slot="_2"
              slot-scope="action, record"
            >
              <span>{{ record.sum||'--' }}</span>
            </template>
            <template slot="times">
              <div class="cursor-pointer bigdropdown  bigdropdown3 wid-102">
                <a-popover
                  :title="null"
                  v-model="visibleTime"
                  trigger="click"
                  placement="bottomRight"
                >
                  <template slot="content">
                    <!-- 日期选择器 -->
                    <a-range-picker
                      @change="setMonth"
                      class="data_picker"
                    >
                      <i
                        slot="suffixIcon"
                        class="meiye-icon meiye-rili-moren"
                        :class="{ 't-c-g': isactive }"
                        style="font-size:12px;color:#9ea6a4 ;background: @baseColor41;margin-top: -5px;"
                      ></i>
                    </a-range-picker>

                  </template>
                  <span style="width:94px;display: inline-block">
                    <i
                      class="meiye-icon meiye-riqi "
                      style="color:#9ea6a4;"
                    ></i>
                    时间
                    <i
                      class="meiye-icon meiye-xiala"
                      style="width:10px; margin-top: -1px;font-size:12px;"
                    ></i>
                  </span>
                </a-popover>
              </div>
            </template>
            <template
              slot="_3"
              slot-scope="action, record"
            >
              <span>{{ record.times||'--' }}</span>
            </template>
            <template
              slot="_4"
              slot-scope="action, record"
            >
              <span>{{ record.serial_number||'--' }}</span>
            </template>
            <template
              slot="_5"
              slot-scope="action, record"
            >
              <a-tooltip>
                <template slot="title">
                  {{ record.notes||'--' }}
                </template>
                <span>{{ record.notes ||'--' }}</span>
              </a-tooltip>
            </template>
          </a-table>

        </div>
      </div>
    </a-modal>

    <!-- 添加备注 -->
    <a-modal
      title="添加备注"
      :visible="memo.memovisible"
      @cancel=";memo.memovisible=false;"
      :width="600"
      class="wid_600-modal"
      @ok=";memo.memovisible=false"
    >
      <div class="adress_box memobox">
        <a-form-model
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-model-item
            label="备注内容"
            required
          >
            <a-textarea
              class="ant-input_1"
              style="width: 414px;height: 72px;resize: none;"
              v-model="memo.content"
              placeholder="请输入备注内容"
              :maxLength="50"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
          <a-form-model-item
            label="上传照片"
            class="momeimgs"
          >
            <div class="up5imgs">
              <upload-image
                @getImageId="getDelImageId"
                @delimageId="delDelimageId"
                @getImageshow="getImageshow"
                :multiple="5"
                :img="memo.images"
                @draggable="handleDraggable"
                ref="uploadCom"
              ></upload-image>
            </div>
            <p class="tips">单张图片不超过1MB，支持格式：jpeg、jpg、png。</p>
          </a-form-model-item>
          <a-form-model-item
            label="上传视频"
            class="momevideo"
          >
            <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="baseUrl+'/upload'"
              :headers="headers"
              :before-upload="beforeUpload"
              :customRequest="uploadFiles"
              @change="handleChange1"
            >
              <div
                id="preview"
                v-if="memo.videos"
              >
                <video id="video"></video>
              </div>

              <div v-else>
                <a-icon :type="memo.url_loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
            <p class="momevideotips">单个视频建议不超过300M，支持视频格式：MP4，MOV。</p>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>

    <!-- 修改到期时间 -->
    <a-modal
      title="修改到期时间"
      :visible="timeoff.visible"
      @cancel=";timeoff.visible=false;"
      :width="600"
      class="wid_600-modal"
      @ok="timeoffOk"
    >
      <div class="adress_box">
        <a-form-model
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >

          <a-form-model-item label="修改到期时间">
            <span
              style="min-width: 364px;display:inline-block;margin-left:15px"
              class="flex align-item-center"
            >
              <a-radio-group
                name="radioGroup"
                class="nomal_radio radio_nomal1"
                v-model="timeoff.timeoffType"
              >
                <a-radio :value="1">
                  无限期
                </a-radio>
                <a-radio :value="2">
                  限期
                </a-radio>
              </a-radio-group>
              <!-- 日期选择器 -->
              <a-range-picker
                v-if="timeoff.timeoffType==2"
                @change="setMonthTimes"
                class="data_picker"
                style="width:248px;"
              >
                <i
                  slot="suffixIcon"
                  class="meiye-icon meiye-rili-moren"
                  :class="{ 't-c-g': isactive }"
                  style="font-size:12px;color:#9ea6a4 ;background: @baseColor41;margin-top: -5px;"
                ></i>
              </a-range-picker>
            </span>
          </a-form-model-item>

        </a-form-model>
      </div>
    </a-modal>

  </div>

</template>
<script>
import moment from 'moment';
import config from '../../config';
import {
  GetmanageGoodslist,
  GetgoodsoptionsList, //门店分类
  TreetypeFinanceList,//财务分类
  TreetypeMallList,//商城分类
  CreateGoods, UpdateGoods, GoodsInfo, GetgoodsbrandsoptionsList
} from '@/api/commodity'
import UploadImage from '../../modules/drag'
import ShopEditor from '../../modules/wangeditor'
import skuSetting from '@/components/intimate/skuSetting/index.vue'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
import { pinyin } from 'pinyin-pro';
import { TagsSider } from '@/components'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import ComposeInput from '@/components/compose_input'

export default {
  inject: ['reload'],
  components: { UploadImage, ShopEditor, skuSetting, TagsSider, ComposeInput },
  data() {
    return {
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Authorization": localStorage.getItem("token"),
        "Content-Type": 'multipart/form-data'
      },
      imgflag: false,
      basicone: false,
      basictwo: false,
      swiperOption: {
        // 所有的参数同 swiper 官方 api 参数
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          // 设置点击可切换
          clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 4,
        // 设置自动轮播
        autoplay: false,
        // 设置轮播可循环
        loop: false
      },
      isdatatimes: 1,
      shoptype: '1',
      selectArr: [
        { name: '订单号', id: '1', key: 1, placeholder: '请输入订单号' },
        { name: '商品名称', id: '2', key: 2, placeholder: '请输入商品名称' },
        // { name: '核销单号', id: '3', key: 3 },
        // { name: '核销项目名称', id: '4', key: 4 },
      ],
      pay: {
        datasource: [
          {
            optime: '2023.06.23',
            operator: '张富贵',
            id: 1,
            memo: ' 顾客不想要',
            // medai: ['https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d941af3444.png', 'https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d943370fdd.mp4']
          },
          {
            id: 2,
            optime: '2023.06.24',
            operator: '李富贵',
            memo: '餐原因是效果',
            // medai: ['https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d941af3444.png', 'https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d943370fdd.mp4']
          },

        ],
        columns: [
          {
            title: '订单号',
            dataIndex: 'optime',
            key: '_0',
            ellipsis: true,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '实际支付',
            dataIndex: 'operator',
            key: '_1',
            ellipsis: true,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '门店',
            dataIndex: 'memo',
            key: '_2',
            // width: 386,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '拿货方式',
            dataIndex: 'memo',
            key: '_3',
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '订单时间',
            dataIndex: 'memo',
            key: '_4',
            scopedSlots: { customRender: '_4' },
          },
          {
            title: '操作',
            dataIndex: 'memo',
            key: '_5',
            scopedSlots: { customRender: '_5' },
          },
        ],
      },
      drawerObj: {
        type: 1,//1储值卡，2卡项，3服务，4寄存产品，5优惠券
        title: "查看储值卡",
        drawerIsShow: false,
      },
      walletDetails: {  // 钱包明细 积分明细 
        visible: false,
        type: 1,// 钱包明细1 积分明细2
        loading: false,
        columns: [],
        columnsw: [//表头
          {
            title: '收支类型',
            dataIndex: 'revenue_expenditure_type ',
            key: '_0',
            ellipsis: true,
            width: 120,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '门店',
            dataIndex: 'store ',
            key: '_1',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_1' }
          },
          {
            title: '金额',
            dataIndex: 'sum',
            key: '_2',
            ellipsis: true,
            scopedSlots: { customRender: '_2' },
          },
          {
            slots: { title: 'times' },
            // title: '时间',
            dataIndex: 'times',
            key: '_3',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '流水号',
            dataIndex: 'serial_number',
            key: '_4',
            ellipsis: true,
            scopedSlots: { customRender: '_4' },
          },
          {
            title: '备注',
            dataIndex: 'notes',
            key: '_5',
            ellipsis: true,
            scopedSlots: { customRender: '_5' },
          },

        ],
        columnsp: [//表头
          {
            title: '收支类型',
            dataIndex: 'revenue_expenditure_type ',
            key: '_0',
            ellipsis: true,
            width: 120,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '门店',
            dataIndex: 'store ',
            key: '_1',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_1' }
          },
          {
            title: '积分',
            dataIndex: 'point',
            key: '_2',
            ellipsis: true,
            scopedSlots: { customRender: '_2' },
          },
          {
            slots: { title: 'times' },
            // title: '时间',
            dataIndex: 'times',
            key: '_3',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '流水号',
            dataIndex: 'serial_number',
            key: '_4',
            ellipsis: true,
            scopedSlots: { customRender: '_4' },
          },
          {
            title: '备注',
            dataIndex: 'notes',
            key: '_5',
            ellipsis: true,
            scopedSlots: { customRender: '_5' },
          },

        ],
        tableData: [
          {
            id: 1,
            revenue_expenditure_type: '新增',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 2,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            point: '+12300.00',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 3,
            revenue_expenditure_type: '手动调整',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 4,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 5,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 6,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 7,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 8,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 9,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 10,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 11,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
        ],
        tableData_w: [],
        tableData_p: [],
        query: {},//参数
      },
      cardsEditRocords: {  // 修改记录
        visible: false,
        type: 1,// 钱包明细1 积分明细2
        loading: false,
        columns: [//表头
          {
            title: '操作类型',
            dataIndex: 'revenue_expenditure_type ',
            key: '_0',
            ellipsis: true,
            width: 120,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '到期时间',
            dataIndex: 'store ',
            key: '_1',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_1' }
          },
          {
            title: '剩余天数',
            dataIndex: 'sum',
            key: '_2',
            ellipsis: true,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '操作人',
            dataIndex: 'times',
            key: '_3',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '操作时间',
            dataIndex: 'serial_number',
            key: '_4',
            ellipsis: true,
            scopedSlots: { customRender: '_4' },
          }
        ],
        tableData: [
          {
            id: 1,
            revenue_expenditure_type: '新增',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 2,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            point: '+12300.00',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 3,
            revenue_expenditure_type: '手动调整',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 4,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 5,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 6,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 7,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 8,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 9,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 10,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
          {
            id: 11,
            revenue_expenditure_type: '消费',
            store: '湖北省武汉市101店',
            sum: '-￥24555.00',
            point: '+12300.00',
            times: '2023/04/28 13:57',
            serial_number: '163888888888888',
            notes: '我睡觉哦...'
          },
        ],
        query: {},//参数
      },
      visibleTime: undefined,
      isactive: false,
      rangeObj: {
        rangeType: '1',//适用范围 2储值卡变动信息 3备注
        tableData: [],
        columns: [],
        loading: false,
        rangeColums: [
          {
            title: '产品名称',
            dataIndex: 'product_name ',
            key: '_0',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '标准单价',
            dataIndex: 'product_price ',
            key: '_1',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_1' },
          },
          {
            // slots: { title: 'types' },
            title: '分类',
            dataIndex: 'types',
            key: '_2',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_2' },
          },
          {
            slots: { title: 'brands' },
            // title: '品牌',
            dataIndex: 'brands',
            key: '_3',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_3' },
          }
        ],
        rangeDate: [],
        cardsColums: [
          {
            title: '时间',
            dataIndex: 'changetime',
            key: '_0',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '金额',
            dataIndex: 'product_price',
            key: '_1',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '收支类型',
            dataIndex: 'pay_types',
            key: '_2',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '门店',
            dataIndex: 'stores',
            key: '_3',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '收银流水',
            dataIndex: 'cashier',
            key: '_4',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_4' },
          }
        ],
        cardsDate: [

        ],
        notesColums: [
          {
            title: '备注内容',
            dataIndex: 'changetime',
            key: '_0',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '备注图片',
            dataIndex: 'product_price',
            key: '_1',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '备注人',
            dataIndex: 'pay_types',
            key: '_2',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '备注时间',
            dataIndex: 'stores',
            key: '_3',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '门店',
            dataIndex: 'cashier',
            key: '_4',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_4' },
          }
        ],
        notesDate: [],
        goods_brand: undefined,
        goods_type: undefined,

      },
      brandsIsShow: false,//品牌
      visibleType: false,//分类
      brandsList: [],//品牌列表 
      // 备注弹框
      memo: {
        memovisible: false,
        content: undefined, // 备注内容
        images: [], // 上传照片 //goods_slider
        videos: undefined, // 上传视频
        datasource: [//备注列表
          {
            optime: '2023.06.23',
            operator: '张富贵',
            id: 1,
            memo: '顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用…顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用…顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用….',
            medai: ['https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d941af3444.png', 'https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d943370fdd.mp4']
          },
          {
            id: 2,
            optime: '2023.06.24',
            operator: '李富贵',
            memo: '顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用…顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用…顾客不想要套餐原因是效果不好顾客不想要套餐原因是效果不好，这里最多可以展示100字，展示不全用….',
            medai: ['https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d941af3444.png', 'https://sandbox.static.meirenji.vip/hly_unknow//2023/06/29/649d943370fdd.mp4']
          },

        ],
        columns: [
          {
            title: '操作时间',
            dataIndex: 'optime',
            key: '_0',
            ellipsis: true,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '操作人',
            dataIndex: 'operator',
            key: '_1',
            ellipsis: true,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '备注内容',
            dataIndex: 'memo',
            key: '_2',
            width: 386,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '资料图片',
            dataIndex: 'medai',
            key: '_3',
            ellipsis: true,
            scopedSlots: { customRender: '_3' },
          },
        ],
        url_loading: false,
        url_loading2: false,
        isactive: false,

      },
      timeoff: {//修改截止时间
        visible: false,
        timeoffType: 1,//1不限 2限制 
        startTime: undefined,
        endTime: undefined,
      },
      // 查看编辑卡项
      planObj: {
        title: '编辑方案',
        drawerIsShow: false,
        rangeType: '1',//卡项信息 2核销信息 3备注
        tableData: [],
        columns: [],
        loading: false,
        rangeColums: [
          {
            title: '组合',
            dataIndex: 'isGroup',
            key: '_0',
            scopedSlots: { customRender: '_0' },
            width: 22,
            customRender: (text, row, index) => {
              const obj = {
                children: (row.group_name || row.group_id) ? '分组' : ' ',
                attrs: {}
              }
              obj.attrs.rowSpan = this.myArray[index];
              return obj
            },
            customCell: (record, rowIndex) => {
              if (record.group_name || record.group_id) {
                return {
                  style: {
                    'text-align': 'center',
                    'padding-left': '0px',
                    'background-color': '#F6F9F9',

                  },
                  class: "guigeTableSpec_th0"
                };
              }
            }
          },
          {
            title: '名称',
            dataIndex: 'goods_name',
            key: '_1',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_1' },
          },
          {
            // slots: { title: 'types' },
            title: '是否参与兑付次数消耗',
            dataIndex: 'types',
            key: '_2',
            ellipsis: true,
            width: 180,
            scopedSlots: { customRender: '_2' },
          },
          {
            // slots: { title: 'brands' },
            title: '金额',
            dataIndex: 'brands',
            key: '_3',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_3' },
          },
          {
            // slots: { title: 'brands' },
            title: '类型',
            dataIndex: 'classes',
            key: '_4',
            ellipsis: true,
            width: 180,
            scopedSlots: { customRender: '_4' },
          },
          {
            title: '最多消耗量',
            dataIndex: 'consume',
            key: '_5',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_5' },
          },
          {
            title: '已消耗权益项',
            dataIndex: 'group_limit_count',
            key: '_6',
            ellipsis: true,
            scopedSlots: { customRender: '_6' },
          },
          {
            title: '操作',
            dataIndex: 'memo',
            key: '_7',
            ellipsis: true,
            fixed: 'right',
            width: 100,
            scopedSlots: { customRender: '_7' },
          }
        ],
        rangeDate: [//需要分组
          {
            id: 1,
            group_id: 67,
            goods_name: '卡一',
            // group_name: '组合',
            product_price: '卡项名称',
            types: '3',
            brands: 23,
            classes: '一级商城分类/二级分类/三级分类',
            consume: '23',
          },
          {
            id: 2,
            // group_name: '组合',
            goods_name: '卡一',
            group_id: 67,
            product_price: '卡项名称',
            types: '3',
            brands: 23,
            classes: '一级商城分类/二级分类/三级分类',
            consume: '23',
          },
          {
            id: 3,
            group_id: 68,
            goods_name: '卡一',
            // group_name: '组合',
            types: '3',
            brands: 23,
            classes: '一级商城分类/二级分类/三级分类',
            consume: '23',
          },
          {
            id: 4,
            group_id: 68,
            goods_name: '卡一',
            // group_name: '组合',
            product_price: '卡项名称',
            types: '3',
            brands: 23,
            classes: '一级商城分类/二级分类/三级分类',
            consume: '23',
          },
          {
            id: 5,
            group_id: 0,
            // group_name: '组合',
            goods_name: '卡一',
            product_price: '卡项名称',
            types: '3',
            brands: 23,
            classes: '一级商城分类/二级分类/三级分类',
            consume: '23',
          },

        ],
        cardsColums: [
          {
            title: '核销时间',
            dataIndex: 'product_name ',
            key: '_0',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '核销次数',
            dataIndex: 'product_price ',
            key: '_1',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_1' },
          },
          {
            // slots: { title: 'types' },
            title: '消耗项',
            dataIndex: 'types',
            key: '_2',
            ellipsis: true,
            width: 180,
            scopedSlots: { customRender: '_2' },
          },
          {
            // slots: { title: 'brands' },
            title: '核销项目',
            dataIndex: 'brands',
            key: '_3',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_3' },
          },
          {
            // slots: { title: 'brands' },
            title: '美容师',
            dataIndex: 'classes',
            key: '_4',
            ellipsis: true,
            width: 180,
            scopedSlots: { customRender: '_4' },
          },
          {
            title: '门店',
            dataIndex: 'consume',
            key: '_5',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_5' },
          },
          {
            title: '操作',
            dataIndex: 'actions',
            key: '_6',
            ellipsis: true,
            // fixed: 'right',
            width: 100,
            scopedSlots: { customRender: '_6' },
          }
        ],
        cardsDate: [
          {
            id: 1,
            product_name: '2023/11/12 10:23:32',
            product_price: 1,
            types: 2,
            brands: '美娜多肩颈理理疗、光子嫩肤美娜多肩颈理理疗、光子嫩肤',
            classes: '张岚岚、张晓芸',
            consume: '湖北省武汉市101店'
          },
          {
            id: 2,
            product_name: '2023/12/30 10:23:32',
            product_price: 6,
            types: 2,
            brands: '美娜多肩颈理理疗、光子嫩肤美娜多肩颈理理疗、光子嫩肤',
            classes: '张岚岚、张晓芸',
            consume: '湖北省武汉市101店'
          }
        ],
        notesColums: [
          {
            title: '备注内容',
            dataIndex: 'changetime',
            key: '_0',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '备注图片',
            dataIndex: 'product_price',
            key: '_1',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '备注人',
            dataIndex: 'pay_types',
            key: '_2',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '备注时间',
            dataIndex: 'stores',
            key: '_3',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '门店',
            dataIndex: 'cashier',
            key: '_4',
            ellipsis: true,
            // width: 180,
            scopedSlots: { customRender: '_4' },
          }
        ],
        notesDate: [],
        goods_brand: undefined,
        goods_type: undefined,
        modalVisible: true, // 核销明细弹框
        modalTitle: '美娜多肩颈按摩理疗',
        conLoading: false,
        conColums: [
          {
            title: '核销时间',
            dataIndex: 'product_time ',
            key: '_0',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_0' },
          },
          {
            title: '核销次数',
            dataIndex: 'product_num ',
            key: '_1',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '消耗项',
            dataIndex: 'product_count ',
            key: '_2',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '美容师',
            dataIndex: 'product_beaty ',
            key: '_3',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_3' },
          },
          {
            title: '门店',
            dataIndex: 'product_store ',
            key: '_4',
            ellipsis: true,
            // width: 120,
            scopedSlots: { customRender: '_4' },
          },
        ],
        mockDataObj: [
          {
            id: 1,
            product_time: '2023/12/12 10:23:32',
            product_num: '23',
            product_count: 3,
            product_beaty: '张岚岚',
            product_store: '湖北省武汉市101店'
          },
          {
            id: 1,
            product_time: '2023/12/12 10:23:32',
            product_num: '23',
            product_count: 3,
            product_beaty: '张岚岚',
            product_store: '湖北省武汉市101店'
          }
        ],
      },
      myArray: [],

    };
  },
  created() {
    // 缓存品牌列表
    this.getBrands()
    // 初始化 卡项列表组合的数据
    // this.setRowSpan(this.planObj.rangeDate, '2');
  },
  methods: {
    moment,
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    changebasic(e) {
      this[e] = !this[e]
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath ? this.$route.fullpath : this.$route.path });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
      this.$forceUpdate();
    },
    changeGglable(e) {
      console.log(e);
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    callback(key) {
      console.log(key);
    },
    getListSearch(e) {
      // console.log(e);
      // this.query.page = 1
      // if (e.activeStatus1.name == "商品名称") {
      //   this.query.goods_name = e.value
      //   this.query.goods_bn = undefined
      //   this.getList()
      // } else {
      //   this.query.goods_name = undefined
      //   this.query.goods_bn = e.value
      //   this.getList()
      // },
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        filter: {
          goods_name: self.query.goods_name,//商品名称
          goods_bn: self.query.goods_bn,//商品码
          goods_type: self.query.goods_type, //1客装 2院装 不传全部
          is_show: self.query.is_show, //1在售 0停售 不传为全部
          goods_brand: self.query.goods_brand == 99999 ? undefined : self.query.goods_brand, //品牌id 不传为全部
          goods_category: self.query.goods_category, //门店分类 不传为全部
          store_category_id: self.query.store_category_id,// 商城分类
          finance_category_id: self.query.finance_category_id,//财务分类
          start_data: self.query.start_data,
          end_data: self.query.end_data,
        },
        page: self.query.page,
        limit: self.query.limit
      }

      return GetmanageGoodslist(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.limit, current: this.query.page }, {
          total: total
        })
        console.log(self.table.tableData, "///self.table.tableData");
        // self.onSelectChange([], [])
      }).finally(r => {
        self.table.loading = false
      })
    },
    addRules(e, i) {
      console.log(e, '/records');
      if (i == 3) {//查看
        // 打开右侧的抽屉 编辑 查看产品
        this.drawerObj.drawerIsShow = true
      } else if (i == 4) {//商品明细
        this.planObj.drawerIsShow = true
        this.setRowSpan(this.planObj.rangeDate, '2');
      }

    },
    afterVisibleChange(val) {
      console.log('visible', val);
    },
    onClose() {
      this.drawerObj.drawerIsShow = false;
    },
    oncardClose() {
      this.planObj.drawerIsShow = false;
    },
    // 打开明细
    openPoint(e) {
      this.walletDetails.visible = true
      this.walletDetails.type = e
      if (e == 1) {
        // 初始化明细
        this.walletDetails.columns = this.walletDetails.columnsw
        // 获取钱包明细接口数据 todo
      }
      if (e == 2) {
        // 初始化明细
        this.walletDetails.columns = this.walletDetails.columnsp
        // 获取积分明细接口数据 todo
      }

    },
    // 明细 时间筛选
    setMonth(date, dateString) {
      this.visibleTime = false;
      this.query.start_data = dateString[0]
      this.query.end_data = dateString[1]
      // this.flitList[4].value = [this.query.start_data + ' ~ ' + this.query.end_data]
      // this.query.dividend_month = dateString;
      // this.getList();
      //todo  获取 钱包消费明细或者积分明细 
    },
    openDetails(r) {
    },
    goEditor() {
      this.timeoff.visible = true
      // 回显信息
    },
    // tabs切换 内容
    callbackRange(e) {
      console.log(e);
      this.rangeObj.rangeType = e
      if (e == 1) {

      }
    },
    // 商品品牌
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
          this.brandsList.unshift({
            brand_icon: "meiye-routeicon-chart-pie",
            brand_id: 99999,
            brand_name: "全部",
            brand_sort: 1,
            brand_status: 1,
            created_at: "2023-04-20 16:30:25",
          })
        }
      })
    },
    //筛选 适用范围
    getbrandsItem(i, v) {
      if (i == 'brands') {
        this.brandsIsShow = false;
        console.log(v, this.activeStatus_brand);
        // if (v == 99999) {
        v = [v]
        let arr = []
        let arr2 = []
        let arr3 = []
        this.brandsList.forEach(el => {
          if (el.brand_id != 99999) {
            arr.push(el.brand_name)
            arr3.push(el.brand_id)
          }
          v.forEach(m => {
            if (m == el.brand_id && m != 9999) {
              arr2.push(el.brand_name)
            }
          })
        })
        // this.query.goods_brand = v.includes(99999) ? arr3 : v
        this.brandsIsShow = false//品牌
      }
      // this.query.page = 1 
      // this.cateIsshow = false//分类 
      // 发送请求 
      // this.getList()


    },
    // 轮播图
    getDelImageId(val) {
      if (this.memo.images && this.memo.images.length > 0) {

      } else {
        this.memo.images = []
      }
      this.memo.images.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.memo.images.forEach((val, key) => {
        if (index === key) {
          this.memo.images.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.memo.images = imgDrag
    },
    // 自动截取视频第一帧作为封面
    captureImage(messageItem) {
      const video = document.getElementById('video');
      video.src = messageItem;
      const preview = document.getElementById('preview');

      const canvas = document.createElement('canvas');//创建一个canvas
      const img = new Image();

      video.onloadeddata = evt => { // 此处特别注意下，监听的是onloadeddata事件，而不是loadedmetadata
        // 防止查看视频时再次触发截取首帧和消息的push操作
        // if (this.state.isShowMedaiModal) {
        //   return;
        // }
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);//绘制图像

        img.src = canvas.toDataURL('image/png');
        // uploadFileBase({
        //   data_base64: img.src,
        //   _token: getConfig()._token,
        // }).then((json) => {
        //   if (json.status) {
        //     this.handleRealPush(messageItem, json.data);
        //   }
        // });  
        this.memo.videos = img.src
        // preview.appendChild(img);
      };
    },
    //上传
    handleChange1(info) {
      if (info.file.status === 'uploading') {
        this.memo.url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.goods_attr_val[0].product_image = response?.data?.url
        this.memo.url_loading = false
      }
    },
    // 单规格 上传规格图
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {

        this.memo.videos = res.data.url
        this.captureImage(res.data.url)
      } else {
        this.$message.error(res.error.message)
      }
    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    // 放大
    openmedia(url) {
      this.meidovisible = true
      this.meidourl = url
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt300M = file.size / 1024 / 1024 > 300
        if (isLt300M) {
          self.$message.error('上传视频大于300MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一个！')
          reject(false)
        }

        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['mp4', 'mov']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.mp4/.mov/ 类型的视频！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 修改到期时间
    timeoffOk() {
      this.timeoff.visible = false
      // 请求接口 到期时间  刷新 baseinfo 数据

    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    setMonthTimes(date, dateString) {
      // this.timeoff.visible = false;
      this.timeoff.startTime = dateString[0]
      this.timeoff.endTime = dateString[0]
      //todo 请求接口 

    },
    getRowClassname(record) {
      if (record.isGroup != null) {
        console.log(record.isGroup, "isGroup");
        return 'guigeTableSpec_th'
      }
    },
    // 设置每一行的rowSpan
    setRowSpan(data, e) {
      // debugger
      //保存上一个name
      var x = "";
      //相同name出现的次数
      var count = 0;
      //该name第一次出现的位置
      var startindex = 0;

      for (var i = 0; i < data.length; i++) {
        //这里是合并name列，根据 情况 自己完善
        var val = data[(i)].group_id ? data[(i)].group_id : data[(i)].group_name
        // var val = data[(i)].group_id;
        // var val = data[(i)].group_name;
        if (val) {
          if (i == 0) {
            x = val;
            count = 1;
            this.myArray[0] = 1
          } else {
            if (val == x) {
              count++;
              this.myArray[startindex] = count;
              this.myArray[i] = 0
            } else {
              count = 1;
              x = val;
              startindex = i;
              this.myArray[i] = 1
            }
          }
        }

      }

      console.log(this.myArray, '/ this.myArray');
    },
  },
  computed: {

  }
};
</script>
<style lang="less" scoped>
.addBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  .saleslip_tabs {
    background: #fff;
    padding-bottom: 24px;
    /deep/ .default_tabs.ant-tabs .ant-tabs-tab::before {
      display: none;
    }
    /deep/ .default_tabs.ant-tabs .ant-tabs-bar {
      height: 38px !important;
      padding: 0 0 0 24px !important;
      line-height: 38px !important;
    }
    /deep/ .default_tabs.ant-tabs .ant-tabs-tab {
      text-shadow: 0 0 0.25px currentColor;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      height: 39px !important;
      padding: 0 !important;
      line-height: 38px !important;
      margin: 0 32px 0 0;
      padding: 0;
      text-decoration: none;
      cursor: pointer;
      font-size: 16px !important;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: @fontColor4;
    }
    /deep/.ant-tabs-tab-active::before {
      display: none;
    }
    /deep/ .ant-tabs-left-no-ctn {
      /deep/ .ant-tabs-left-bar {
        border-right: 2px solid #bfd6d4;
        float: left;
        margin-right: -1px;
        margin-bottom: 0;
        height: 100%;
        border-bottom: 0;
      }
      /deep/ .ant-tabs-tab-active::before {
        display: none;
      }
      /deep/ .ant-tabs-tab::after {
        display: none;
      }
      // width: 74px;
      /deep/ .ant-tabs-tab {
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
      .ant-tabs-bar {
        height: auto !important;
        padding-left: 0;
        padding-top: 0;
        background: transparent !important;
        border-bottom: none;
      }
    }
  }
  .add {
    margin: 16px;
    .a_slide_left {
      background: #fff;
      margin-right: 16px;
      width: 426px !important;
      max-width: 426px !important;
      min-width: 426px !important;
      // height: calc(100% - 280px);
      overflow: hidden;
      overflow-y: auto;

      .basic {
        width: 378px;
        // height: 161px;
        border-radius: 8px;
        margin: 24px;
        padding: 24px 0 0 16px;
        .basic_nam {
          margin-bottom: 6px;
          .names {
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: @fontColor4;
          }
          .uid {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: @fontColor4;
            margin-left: 6px;
          }
        }
        .basic_phone {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: @fontColor4;
          margin-bottom: 16px;
        }
        .basic_content {
          .basic_contentLeft {
            width: 172px;
            height: 48px;
          }
          .basic_contentLine {
            width: 1px;
            height: 25px;
            background: rgba(200, 221, 217, 0.8);
            margin-right: 16px;
            margin-top: 4px;
          }
          .basic_contentLeft_top {
            // width: 28px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: @fontColor3;
            line-height: 20px;
            margin-bottom: 4px;
            .basic_contentLeft_toptxt {
              font-weight: 500;
            }
            /deep/.anticon-right {
              font-size: 12px;
            }
          }
          .details {
            margin: 0 4px 0 6px;
            font-weight: 400;
          }
          .basic_contentLeft_bot {
            height: 24px;
            font-size: 16px;
            font-family: UDC1.04-Bold, UDC104;
            font-weight: bold;
            color: @fontColor4;
            line-height: 24px;
          }
        }
      }
      .informationone {
        margin-bottom: 32px;
        .informationone_content {
        }
      }
    }
    .a_slide_head {
      height: 68px;
      line-height: 68px;
      background: #fff;
      box-shadow: none;
    }
    .a_slide_body.ant-layout-content {
      height: 243px !important;
      margin: 16px 0;
      background: #fff;
      flex: none;
    }
    .a_slide_foot {
      background: #fff;
      flex: 1;
      padding: 24px 0 0px;
    }
  }
}
.mgr-16 {
  margin-right: 16px;
}
.height_100 {
  // height: 100%;
  height: calc(100vh - 135px);
  // overflow: hidden;
  // overflow-y: auto;
}
.twoh2 {
  display: flex;
  margin-bottom: 0px;
  margin-left: 40px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: @fontColor4;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin: 5px 5px 0 0;
  }
  .twoh2_rgt .ttxt {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor5;
    margin-right: -2px;
    cursor: pointer;
    // height: 22px;
    // font-size: 14px;
    // font-weight: 500;
    // color: @fontColor1;
    // line-height: 22px;
  }
}
.mgr-26 {
  margin-right: 26px;
}
.colorjt {
  color: #57605d;
}
.pdr-26 {
  padding-right: 26px;
}
.revent {
  transform: rotate(180deg);
  display: inline-block;
}
.leftBabletxt {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor3;
  width: 99px;
  text-align: left;
}
.rightBabletxt {
  display: inline-block;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor4;
}
.mgl-10 {
  margin-left: 10px;
}
.mgl-40 {
  margin-left: 40px;
}
.mgb-24 {
  margin-bottom: 24px;
}
.mgb-20 {
  margin-bottom: 20px;
}
.pdb-40 {
  padding-bottom: 40px;
}
.pdl-24 {
  padding-left: 24px !important;
}
.pdl-12 {
  padding-left: 12px;
}
.lable_radiogroup {
  margin: 24px 0 20px 0px;
}
.swiper {
  height: 150px;
  /deep/ .swiper-slide {
    width: 308px !important;
    height: 150px;
    background: #ffffff;
    box-shadow: inset 0px -3px 0px 0px #e1f4f1;
    border-radius: 4px;
    border: 1px solid #aed6d8;
    margin-right: 16px;
    // padding-top: 12px;
    &:hover {
    }
  }
}
.swiper-button-black {
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 0px;
  left: auto;
  width: 45px;
  height: 152px;
  background: #ffffff;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.18);
  top: 22px;
  line-height: 152px;
  text-align: center;
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  left: 0px;
  left: auto;
  width: 45px;
  height: 152px;
  background: #ffffff;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.18);
  top: 22px;
  line-height: 152px;
  text-align: center;
}

.remote {
  transform: rotate(180deg);
  display: inline-block;
}
.swiper-button-disabled {
  display: none;
}
.mgr-4 {
  margin-right: 4px;
}
.font4 {
  color: @fontColor4;
}
.wid-38 {
  width: 38px;
}
.weight500 {
  font-weight: 500;
}
.fat-right {
  float: right;
  display: inline-block;
}
.family_spe {
  font-family: UDC1.04-Mediun, UDC104;
}
.cards_row1 {
}
.cards_line {
  width: 284px;
  height: 1px;
  background: #e8edec;
  margin-top: 8px;
  margin-bottom: 6px;
}
.cards_row2 {
  // width: 24px;
  height: 20px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor5;
  line-height: 20px;
}
.cards_row3 {
  width: 101px;
  height: 32px;
  font-size: 20px;
  font-family: UDC1.04-Bold, UDC104;
  font-weight: bold;
  color: @baseColor11;
  line-height: 32px;
  margin-top: -4px;
}
.cards_row4 {
  margin-top: 1px;
  // justify-content: space-between;
  .cards_row4_left,
  .cards_row4_right {
    width: 152px;
    margin-left: 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .cards_row4_ltop {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @fontColor5;
  }
  .cards_row4_lbot {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @fontColor4;
  }
}
.addBox .add {
  /deep/.ant-tabs-ink-bar {
    background-color: @baseColor11 !important;
  }
}
.filter-wrap {
  padding-left: 24px;
}
/deep/ .owninputbox {
  width: 320px !important;
}
.guigeTablebox {
  padding: 0 24px;
}
// 卡片的蒙板
.sliderBox {
  position: relative;
  width: 308px;
  height: 150px;
  padding-top: 8px;
  .cards_row1 {
    margin: 0px 12px 0 12px;
  }
  .sliderBoxboard {
    display: none;
    position: absolute;
    top: -1px;
    left: -1px;
    width: 308px;
    height: 150px;
    background: linear-gradient(
      180deg,
      rgba(89, 105, 101, 0.2) 0%,
      rgba(45, 56, 53, 0.64) 100%
    );
    border-radius: 5px;
  }
  &:hover {
    .sliderBoxboard {
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
/deep/.ant-drawer-body {
  padding-right: 0px;
}
.baseIndo {
  margin-left: 9px;
  padding: 0 0 24px 0;
  border-bottom: 1px solid @dropdownBs5a;
  .baseLeft {
    p {
      margin: 0;
      padding: 0;
    }
    .pnames {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .pshops {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.consumeInfo {
  margin-left: 9px;
  padding: 26px 33px 0px 0;
  font-size: 14px;
  .labletxt {
    display: inline-block;
    width: 113px;
    text-align: left;
    color: @fontColor3;
  }
  .valuetxt {
    display: inline-block;
    width: 320px;
    text-align: left;
    color: @fontColor4;
  }
  .valuetxt_position {
    position: relative;
  }
  .boardcards {
    width: 884px;
    height: 92px;
    background: #fafcfc;
    border-radius: 8px;
    border: 1px solid @borderColorBase;
    justify-content: space-around;
    align-items: center;
  }
}
.boardcards {
  width: 884px;
  height: 92px;
  background: #fafcfc;
  border-radius: 8px;
  border: 1px solid @borderColorBase;
  justify-content: space-around;
  align-items: center;
}
.toeditoricon {
  display: inline-block;
  // right: 86px;
  // top: 0px;
  width: 21px;
  line-height: 17px;
  text-align: center;
  height: 21px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(173, 207, 203, 0.35),
    inset 0px -2px 0px 0px #dbeae8;
  border-radius: 4px;
  border: 1px solid #bacbc7;

  .meiye-bianji {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    transform: scale(0.85);
    color: @fontColor4;
  }
  &:hover {
    border: 1px solid @primaryColor;
    .meiye-bianji {
      color: @primaryColor;
    }
  }
}
.mgb-8 {
  margin-bottom: 8px;
}
.font18 {
  font-size: 18px;
}
.mgt-40 {
  margin-top: 40px;
}
.mgl-8 {
  margin-left: 8px;
}
.mgt-9 {
  margin-top: 9px;
}
.rangebox .twoh2 {
  margin-left: 0px;
}
/deep/ .rangebox .ant-spin-nested-loading {
  min-height: 50px;
}
/deep/ .rangebox .popTable3 .ant-table-placeholder {
  width: auto;
  margin-top: 7px;
  border-top: none;
}
.mgb-12 {
  margin-bottom: 12px;
}
.mgt-40 {
  margin-top: 40px;
}
/deep/.up5imgs .ant-spin-container {
  justify-content: flex-start;
}
.memobox {
  .momeimgs {
    margin-bottom: 15px;
  }
  .momevideo {
    .momevideotips {
      padding: 0;
      margin: -20px 0 0 0;
    }
  }
}
/deep/.ant-upload-select-picture-card i {
  font-size: 18px;
  color: #c9d2d4;
}
/deep/.ant-upload-select-picture i {
  font-size: 18px;
  color: #c9d2d4;
}
.medioinner {
  width: 30px;
  height: 23px;
  border-radius: 2px;
  position: relative;
  .medaiImg {
    width: 30px;
    height: 23px;
    border-radius: 2px;
    opacity: 0.8;
  }
  .menban {
    display: none;
    width: 30px;
    height: 23px;
    text-align: center;
    line-height: 23px;
    position: absolute;
    background: rgba(0, 0, 0, 0.36);
    left: 0;
    top: 0;
    .menbaneye {
      display: inline-block;
      color: #fff;
    }
  }
  &:hover {
    .menban {
      display: inline-block;
      width: 30px;
      height: 23px;
      text-align: center;
      line-height: 23px;
      position: absolute;
      background: rgba(0, 0, 0, 0.36);
      left: 0;
      top: 0;
      .menbaneye {
        display: inline-block;
        color: #fff;
      }
    }
  }
}

.memolistspan {
  width: 336px;
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor3;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: -webkit-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
/deep/ .memobox .ant-spin-nested-loading {
  min-height: 40px;
}
.shopdetails {
  margin-bottom: 16px;
  .shopdetails_img {
    width: 47px;
    height: 36px;
    margin-right: 8px;
    background: rgb(162, 200, 225);
  }
  .shopdetails_txt {
    p {
      margin: 0;
      padding: 0;
    }
    .shopdetails_names {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: @fontColor4;
    }
    .shopdetails_gg {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @fontColor3;
    }
  }
}
// 查看卡项抽屉
.plancardsboard {
  width: 884px;
  height: 168px;
  background: #fafcfc;
  border-radius: 8px;
  border: 1px solid @borderColorBase;
  justify-content: space-around;
  align-items: center;
  margin: 8px 0 24px 0;
}
/deep/ .ant-table-tbody tr.guigeTableSpec_th {
  &:hover {
    .guigeTableSpec_th0 {
      color: @baseColor11 !important;
    }
  }
}
/deep/ .ant-table-tbody tr.guigeTableSpec_th .guigeTableSpec_th0 {
  color: @baseColor11 !important;
}
</style>